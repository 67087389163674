<template>
  <mobile-screen
    :header="true"
    screen-class="icon-app1 realtime-screen gray-bg"
  >
    <template v-slot:header>
      <top-header-menu-wrapper
        menu-class="realtime-header icon-hea1"
        :helpOnline="
          Boolean(
            helpOnline[helpSlug] &&
              helpOnline[helpSlug].name &&
              helpOnline[helpSlug].content
          )
        "
        :helpOnlineRoute="{
          name: 'r_realtime-activate-help-online',
        }"
      >
        <template v-slot:left>
          <router-link :to="{ name: 'r_realtime' }">
            <button>
              <icon icon="#cx-hea1-arrow-left" />
            </button>
          </router-link>
        </template>
        <div class="component-title" v-if="map">
          {{ map.name }}
        </div>
        <template v-slot:right>
          <button @click="updateDeviceTokens()">
            <icon icon="#cx-hea1-save" />
          </button>
        </template>
      </top-header-menu-wrapper>
    </template>
    <ul class="clebex-item-section" v-if="map">
      <li class="clebex-item-section-item">
        <div class="clebex-item-content-wrapper">
          <dl class="clebex-item-dl justify-start">
            <dt class="clebex-item-dt">
              <div class="checkbox slide">
                <input
                  id="mapActive"
                  type="checkbox"
                  v-model="active"
                  @change="changeActive"
                />
                <label for="mapActive"></label>
              </div>
            </dt>
            <dd class="clebex-item-dd justify-start">
              {{ displayLabelName("realtime", "map", "active") }}
            </dd>
          </dl>
        </div>
      </li>
    </ul>
    <ul class="clebex-item-section" v-if="map">
      <li class="clebex-item-section-item">
        <div class="clebex-item-content-wrapper">
          <div class="clebex-section-input">
            <p class="clebex-section-input-label">
              {{ displayLabelName("realtime", "map", "url-occupancy") }}
            </p>
            <p
              class="text-label"
              :class="{ disabled: !active }"
              v-if="occupencyField"
            >
              {{ occupencyField }}
            </p>
          </div>

          <button v-if="active" @click="copyFieldValue(occupencyField)">
            <span class="follow-up-icons">
              <span class="follow-up-icon-item">
                <icon icon="#cx-app1-copy"></icon>
              </span>
            </span>
          </button>
        </div>
      </li>

      <li class="clebex-item-section-item">
        <div class="clebex-item-content-wrapper">
          <div class="clebex-section-input">
            <p class="clebex-section-input-label">
              {{ displayLabelName("realtime", "map", "url-plan") }}
            </p>
            <p
              class="text-label"
              :class="{ disabled: !active }"
              v-if="planField"
            >
              {{ planField }}
            </p>
          </div>

          <button v-if="active" @click="copyFieldValue(planField)">
            <span class="follow-up-icons">
              <span class="follow-up-icon-item">
                <icon icon="#cx-app1-copy"></icon>
              </span>
            </span>
          </button>
        </div>
      </li>
    </ul>
    <p class="with-resource-id">
      {{ displayLabelName("realtime.map.with-resource-id") }}
    </p>
    <ul class="clebex-item-section" style="margin-top: 20px;" v-if="map">
      <li class="clebex-item-section-item">
        <div class="clebex-item-content-wrapper">
          <div class="clebex-section-input">
            <p class="clebex-section-input-label">
              {{ displayLabelName("realtime", "map", "url-occupancy") }}
            </p>
            <p
              class="text-label"
              :class="{ disabled: !active }"
              v-if="occupencyFieldWithResourceID"
            >
              {{ occupencyFieldWithResourceID }}
            </p>
          </div>

          <button
            v-if="active"
            @click="copyFieldValue(occupencyFieldWithResourceID)"
          >
            <span class="follow-up-icons">
              <span class="follow-up-icon-item">
                <icon icon="#cx-app1-copy"></icon>
              </span>
            </span>
          </button>
        </div>
      </li>

      <li class="clebex-item-section-item">
        <div class="clebex-item-content-wrapper">
          <div class="clebex-section-input">
            <p class="clebex-section-input-label">
              {{ displayLabelName("realtime", "map", "url-plan") }}
            </p>
            <p
              class="text-label"
              :class="{ disabled: !active }"
              v-if="planFieldWithResourceID"
            >
              {{ planFieldWithResourceID }}
            </p>
          </div>

          <button
            v-if="active"
            @click="copyFieldValue(planFieldWithResourceID)"
          >
            <span class="follow-up-icons">
              <span class="follow-up-icon-item">
                <icon icon="#cx-app1-copy"></icon>
              </span>
            </span>
          </button>
        </div>
      </li>
    </ul>
    <ul class="clebex-item-section" v-if="map">
      <!-- Start Token -->
      <li class="clebex-item-section-item">
        <div class="clebex-item-content-wrapper">
          <div class="clebex-section-input">
            <label class="clebex-section-input-label" for="token">{{
              displayLabelName(
                "visitor-management",
                "manage-reception-desks",
                "token"
              )
            }}</label>
            <Field
              v-model="map.token"
              id="token"
              name="token"
              as="input"
              type="text"
            />
          </div>
        </div>
      </li>
      <!-- End Token -->
    </ul>
    <div
      style="width: 100%; color: #999999; padding: 10px; padding-right: 20px; text-align: right;"
    >
      <span style="cursor: pointer;" @click="deleteDeviceTokenModal = true"
        >{{
          displayLabelName("resources", "resource-function", "delete-token")
        }}
        ({{ usedTokens }}/{{ token ? token : 0 }})</span
      >
    </div>
    <template v-slot:footer>
      <nav class="actions-menu theme-gray"></nav>
    </template>
    <screen-modal
      v-if="deleteDeviceTokenModal"
      class="confirm-modal"
      type="success"
      :confirm-action="deleteDeviceTokens"
      :confirm-button-label="displayLabelName('global.buttons.ok')"
      :cancelButtonLabel="displayLabelName('global.buttons.cancel')"
      :show="deleteDeviceTokenModal"
      @close="deleteDeviceTokenModal = false"
    >
      <h3 class="modal-title">
        {{
          displayLabelName("resources", "messages", "delete-device-token-title")
        }}
      </h3>
      <p class="text">
        {{
          displayLabelName("resources", "messages", "delete-device-token-text")
        }}
        <br />
        {{ displayLabelName("global.messages.delete-action-question") }}
      </p>
    </screen-modal>
  </mobile-screen>
  <router-view />
</template>

<script>
import MobileScreen from "@/layouts/MobileScreen";
import TopHeaderMenuWrapper from "@/components/global/TopHeaderMenuWrapper";
import { mapState, mapActions } from "vuex";
import { getSubdomain, getLang } from "@/services/http-service";
import helpOnlineMixin from "@/services/mixins/help_online/help-online-mixin";
import httpServiceAuth from "@/services/http-service";
import { apiEndpoints } from "@/services/constants";
import { errorHandler } from "@/services/error-handler";
import mapMixin from "@/services/mixins/map/map";

export default {
  name: "RealtimeMap",
  mixins: [helpOnlineMixin, mapMixin],
  components: {
    MobileScreen,
    TopHeaderMenuWrapper,
  },
  data() {
    return {
      helpSlug: "real-time-activate",
      token: 0,
      usedTokens: 0,
      deleteDeviceTokenModal: false,
    };
  },
  async created() {
    await this.getMap(this.$route.params.mapId);
    this.token = this.map.token;
    this.usedTokens = this.map.used_tokens;
  },
  computed: {
    ...mapState("realtime", ["map", "active", "token"]),
    occupencyField() {
      const url = `${process.env.VUE_APP_5DMAPS_URL}/realtime/${
        this.$route.params.mapId
      }?api=${this.getMapApi()}&subdomain=${getSubdomain()}&lang=${getLang()}&type=sensor`;
      return url;
    },
    planField() {
      const url = `${process.env.VUE_APP_5DMAPS_URL}/realtime/${
        this.$route.params.mapId
      }?subdomain=${getSubdomain()}&lang=${getLang()}&type=occupancy`;
      return url;
    },
    occupencyFieldWithResourceID() {
      const url = `${process.env.VUE_APP_5DMAPS_URL}/realtime/${
        this.$route.params.mapId
      }?api=${this.getMapApi()}&subdomain=${getSubdomain()}&lang=${getLang()}&type=sensor&with=resourceid`;
      return url;
    },
    planFieldWithResourceID() {
      const url = `${process.env.VUE_APP_5DMAPS_URL}/realtime/${
        this.$route.params.mapId
      }?subdomain=${getSubdomain()}&lang=${getLang()}&type=occupancy&with=resourceid`;
      return url;
    },
  },
  methods: {
    ...mapActions("realtime", ["getMap", "getMaps", "updateMap"]),
    changeActive() {
      this.updateMap(this.$route.params.mapId);
    },
    copyFieldValue(value) {
      const el = document.createElement("textarea");
      el.value = value;
      el.setAttribute("readonly", "");
      el.style.position = "absolute";
      el.style.left = "-9999px";
      document.body.appendChild(el);
      const selected =
        document.getSelection().rangeCount > 0
          ? document.getSelection().getRangeAt(0)
          : false;
      el.select();
      document.execCommand("copy");
      document.body.removeChild(el);
      if (selected) {
        document.getSelection().removeAllRanges();
        document.getSelection().addRange(selected);
      }
    },
    deleteDeviceTokens() {
      this.$store.commit("loader/setScreenLoading", true, {
        root: true,
      });

      let values = {};

      httpServiceAuth
        .post(
          `${apiEndpoints.company.realtimeDeleteTokens}/${this.$route.params.mapId}`,
          values
        )
        .catch((error) => {
          if (error.response) {
            errorHandler(error.response, this.findElement());
          }
        })
        .finally(() => {
          this.$store.commit("loader/setScreenLoading", false, {
            root: true,
          });
        });
    },
    async updateDeviceTokens() {
      this.$store.commit("loader/setScreenLoading", true, {
        root: true,
      });

      let values = {
        token: this.map.token,
      };

      httpServiceAuth
        .post(
          `${apiEndpoints.company.realtimeUpdateTokens}/${this.$route.params.mapId}`,
          values
        )
        .catch((error) => {
          if (error.response) {
            errorHandler(error.response, this.findElement());
          }
        })
        .finally(() => {
          this.getMap(this.$route.params.mapId);
          this.token = this.map.token;
          this.usedTokens = this.map.used_tokens;
          this.$store.commit("loader/setScreenLoading", false, {
            root: true,
          });
        });
    },
  },
  watch: {
    $route() {
      if (this.$route.name === "r_realtime-map") {
        this.getMap(this.$route.params.mapId);
      }
    },
  },
};
</script>
<style scoped>
.with-resource-id {
  color: rgb(153, 153, 153);
  margin-top: 30px;
  margin-left: 17px;
}
</style>
